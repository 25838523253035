import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, effect, inject } from '@angular/core';
import { EnergyStopService } from '@app/services/energy-stop/services/energy-stop.service';
import { firstValueFrom } from 'rxjs';
import { EnergyStop } from '@app/services/energy-stop/classes/energy-stop';
import { DbUtils } from '@core/utils';
import { TransactionStore } from '@app/transaction/store/transaction.store';
import { EnergyStopRequest } from '@app/services/energy-stop/types/energy-stop-request';
import { EnergyStopSuppliersStatus } from '@app/services/energy-stop/enums/energy-stop-suppliers-status';

type EnergyStopState = {
    isLoading: boolean;
    energyStop: EnergyStop;
};

const defaultState: EnergyStopState = {
    isLoading: false,
    energyStop: null,
};

export const EnergyStopStore = signalStore(
    { providedIn: 'root' },
    withState(defaultState),
    withComputed((state) => ({
        energyStopActiveServices: computed(() => {
            const energyStop = state?.energyStop();
            if (!energyStop) return null;
            return energyStop.energyStopActiveServices;
        }),
        suppliersComplete: computed(() => {
            const energyStop = state?.energyStop();
            if (!energyStop?.states) return false;
            return energyStop.states.suppliersStatus === EnergyStopSuppliersStatus.Complete;
        }),
        supplierGas: computed(() => {
            const energyStop = state?.energyStop();
            if (!energyStop) return null;
            return energyStop.supplierGas;
        }),
        supplierElectricity: computed(() => {
            const energyStop = state?.energyStop();
            if (!energyStop) return null;
            return energyStop.supplierElectricity;
        }),
    })),
    withMethods((store, energyStopService = inject(EnergyStopService)) => ({
        async load(transactionId: string) {
            patchState(store, { isLoading: true });
            try {
                const { states, energyStopActiveServices, supplierElectricity, supplierGas, status, active } = await firstValueFrom(
                    energyStopService.getEnergyStop(transactionId)
                );
                patchState(store, {
                    isLoading: false,
                    energyStop: new EnergyStop({
                        states,
                        energyStopActiveServices,
                        supplierElectricity,
                        supplierGas,
                        status,
                        active,
                    }),
                });
            } catch (error) {
                patchState(store, {
                    isLoading: false,
                });
            }
        },
        async patch(transactionId: string, energyStopRequest: EnergyStopRequest) {
            patchState(store, { isLoading: true });
            try {
                const { states, energyStopActiveServices, supplierElectricity, supplierGas, status, active } = await firstValueFrom(
                    energyStopService.patchEnergyStop(transactionId, energyStopRequest)
                );
                patchState(store, {
                    isLoading: false,
                    energyStop: new EnergyStop({
                        states,
                        energyStopActiveServices,
                        supplierElectricity,
                        supplierGas,
                        status,
                        active,
                    }),
                });
            } catch (error) {
                patchState(store, {
                    isLoading: false,
                });
            }
        },
        async setSubmitted(transactionId: string, submitted: boolean) {
            patchState(store, { isLoading: true });
            try {
                const updated = await firstValueFrom(energyStopService.submitEnergyStop(transactionId, submitted));
                patchState(store, {
                    isLoading: false,
                    energyStop: new EnergyStop(updated),
                });
            } catch (error) {
                patchState(store, {
                    isLoading: false,
                });
            }
        },
        async setActive(transactionId: string, active: boolean) {
            patchState(store, { isLoading: true });
            try {
                const updated = await firstValueFrom(energyStopService.setEnergyStopActive(transactionId, active));
                patchState(store, {
                    isLoading: false,
                    energyStop: new EnergyStop(updated),
                });
            } catch (error) {
                patchState(store, {
                    isLoading: false,
                });
            }
        },
    })),
    withHooks(({ load }) => {
        const transactionStore = inject(TransactionStore);

        effect(
            () => {
                const transactionId = DbUtils.getStringId(transactionStore.transactionId());
                if (transactionId) {
                    load(transactionId);
                }
            },
            { allowSignalWrites: true }
        );

        return {
            onInit() {},
        };
    })
);
