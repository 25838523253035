import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { EnergyStopResponse } from '@app/services/energy-stop/types/energy-stop-response';
import { inject, Injectable } from '@angular/core';
import { EnergyStopRequest } from '@app/services/energy-stop/types/energy-stop-request';
import { baseUri, baseUriActive, baseUriSubmit } from '@app/services/energy-stop/constants/url.constants';
import { StringUtils } from '@core/utils';

@Injectable({
    providedIn: 'root',
})
export class EnergyStopService {
    private readonly httpClient = inject(HttpClient);

    public getEnergyStop(transactionId: string): Observable<EnergyStopResponse> {
        return this.httpClient.get<EnergyStopResponse>(StringUtils.parseUri(baseUri, { transactionId }));
    }

    public patchEnergyStop(transactionId: string, energyStopRequest: EnergyStopRequest): Observable<EnergyStopResponse> {
        return this.httpClient.patch<EnergyStopResponse>(StringUtils.parseUri(baseUri, { transactionId }), energyStopRequest);
    }

    public submitEnergyStop(transactionId: string, submit: boolean): Observable<EnergyStopResponse> {
        return this.httpClient.patch<EnergyStopResponse>(StringUtils.parseUri(baseUriSubmit, { transactionId }), { value: submit });
    }

    public setEnergyStopActive(transactionId: string, active: boolean): Observable<EnergyStopResponse> {
        return this.httpClient.patch<EnergyStopResponse>(StringUtils.parseUri(baseUriActive, { transactionId }), { value: active });
    }
}
